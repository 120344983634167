import React, { SetStateAction } from 'react'
import loadable from '@loadable/component'
import { ArticleComponentType } from '../../../shared/types'
import { PropertyType, PropertyUnitsType, MetaDataType, ComponentType } from './PropertyPageContent.utils'
import CeilaBrochureImg from '../../../assets/images/ciela-at-aera-heights.webp'

import PropertyPageSlider from '../../../components/PropertyPageSlider/PropertyPageSlider'
import { Link } from 'gatsby'

const ConstructionModal = loadable(() => import('../../../components/ConstructionModal/ConstructionModal'))
const ContentComponents = loadable(() => import('../../../components/ContentComponents/ContentComponents'))
const ComponentThirteen = loadable(() => import('../../../components/ContentComponents/ComponentThirteen/ComponentThirteen'))

type PropertyPageContentProps = {
  propertyComponents: ArticleComponentType[] | null
  property: PropertyType
  unit: PropertyUnitsType
  getVirtualTourImg: () => any
  brochureComponent: ArticleComponentType | null
  constructionImage: MetaDataType
  toggleModal: React.Dispatch<SetStateAction<boolean>>
  showModal: boolean
  brochureTableComponent: ComponentType
}

export default function PropertyPageContent({
  propertyComponents,
  property,
  unit,
  getVirtualTourImg,
  brochureComponent,
  constructionImage,
  toggleModal,
  showModal,
  brochureTableComponent
}: PropertyPageContentProps): JSX.Element {
  const isCielaHeights = property.slug === 'ciela-at-aera-heights'
  const cielaBrochure = 'https://brochure.ayalalandpremier.com/view/645609973/'
  const unitDetails = unit?.overviewDetails?.overviewDetails

  const interactiveBrochure = isCielaHeights ? cielaBrochure : unitDetails?.file ? unitDetails?.file : brochureComponent?.description
  const virtualTourImage = getVirtualTourImg().image
  const virtualTourVideo = unit?.floorPlans?.videos[0]?.video

  return (
    <div className="property-page-content">
      <h1 style={{ display: 'none' }}>{property?.propertyDetails?.projectName}</h1>
      <ContentComponents
        components={propertyComponents}
        noMarginTop={true}
        interactiveBrochure={interactiveBrochure}
        virtualTourImage={virtualTourImage}
        virtualTourVideo={virtualTourVideo}
      />
      <div className="brochure-fold">
        {property?.propertyDetails?.propertyType !== 'Townhouse'
          ? brochureTableComponent && <ComponentThirteen isContentOnly={true} component={brochureTableComponent} />
          : null}
      </div>

      {unit?.floorPlans?.unitsFloorPlans[0]?.imageGallery ? (
        <PropertyPageSlider {...{ floorPlans: property?.propertyUnits ? unit?.floorPlans : [] }} />
      ) : null}

      <ConstructionModal {...{ width: 'auto', height: 'auto%', showModal, toggleModal }}>
        <div className="construction-image">
          <img loading="lazy" src={constructionImage?.image} alt={constructionImage?.altText ?? 'Construction image'} />
        </div>
      </ConstructionModal>

      {property?.propertyDetails?.propertyType !== 'Townhouse' ? (
        <div className="bottom-links-container">
          {property?.propertyDetails?.propertyType !== 'Townhouse' ? (
            <div className="bottom-links">
              <div className="content-links">
                <Link to="/inquire/">
                  <p>Make an Inquiry</p>
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
